exports.onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        require(`intersection-observer`);
    }
};

exports.onInitialClientRender = () => {
    require("typeface-montserrat");
    require("typeface-raleway");
};
